@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@font-face {
  font-family: "din" ;
  src: url(../../fonts/din_black/din_black-webfont.woff) format("woff"),
       url(../../fonts/din_black/din_black-webfont.woff2) format("woff2");
       font-weight: 900;
       font-style: normal;
}
@font-face {
  font-family: "din" ;
  src: url(../../fonts/din_bold/din_bold-webfont.woff) format("woff"),
       url(../../fonts/din_bold/din_bold-webfont.woff2) format("woff2");
       font-weight: 700;
       font-style: normal;
}
@font-face {
  font-family: "din" ;
  src: url(../../fonts/din_medium/din_medium-webfont.woff) format("woff"),
       url(../../fonts/din_medium/din_medium-webfont.woff2) format("woff2");
       font-weight: 500;
       font-style: normal;
}

@font-face {
  font-family: "din" ;
  src: url(../../fonts/din_light/din-webfont.woff) format("woff"),
       url(../../fonts/din_light/din-webfont.woff2) format("woff2");
       font-weight: 400;
       font-style: normal;
}



//Colors


$white: #ffffff;
$dgrey: #666666;
$grey: #999999;
$lgrey:#CCCCCC;;
$lblack: #222222;
$black: #080808;
$dblack: #121212;
$tertiaryBlack: #060808;
$blue:#2A4BBD;
$darkblue:#192D71;
$navyblue:#111E4C;
$purple: #7C99FF;
$red:#EB6E73;
$darkred:#E31B23;
$danger:#DD0D15;
$success:#4EE498;
$yellow:#FBBF24;
$ldanger:#DC2626;

// Font Famlily
$inter: 'Inter', sans-serif;
$din :  'din' , sans-serif;

@function toRem($value) {
  $remValue: ($value / 16) + rem;
  @return $remValue;
}

// font sizes

$primary-fs: 16px;
$secondary-fs: 14px;
$tertiary-fs: 12px;

$h1-font-size: toRem(72);
$h2-font-size: toRem(52);
$h3-font-size: toRem(32);
$h4-font-size: 24px;
$h5-font-size: 24px;
$h6-font-size: 16px;
$p-font-size:  14px;

// Media Quries
$breakpoint-xl: "only screen and (min-width: 1200px) and (max-width:1440px)";
$breakpoint-lg: "only screen and (min-width:992px) and (max-width:1199px)";
$breakpoint-lg-only: "only screen and (min-width:1200px)";
$breakpoint-lg-max: "only screen and (max-width:1199px)";
$breakpoint-md: "only screen and (min-width:768px) and (max-width:991px)";
$breakpoint-sm: "only screen and (min-width:576px) and (max-width:767px)";
$breakpoint-sm-only: "only screen and (max-width:991px)";
$breakpoint-xs: "only screen and (max-width:767px)";
$breakpoint-xxs: "only screen and (max-width: 375px)";
$breakpoint-xs-575: "only screen and (max-width: 575px)";
$breakpoint-above-xs: "only screen and (min-width: 767px)";
