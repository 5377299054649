* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  body {
    a {
      text-decoration: none;
      outline: none !important;
      &:hover {
        text-decoration: none;
      }
    }
    background: $black;
    // Headings
    h1,
    h2,
    h3,
    h4 {
      font-family: $din;
    }
    h5,
    h6 {
      font-family: $inter;
    }
    h1,
    .h1 {
      font-size: $h1-font-size;
      line-height: toRem(65);
      font-weight: 900;
      color: $white;
    }
    h2,
    .h2 {
      font-size: $h2-font-size;
      font-weight: 700;
      line-height: toRem(79);
      color: $white;
    }
    h3,
    .h3 {
      font-size: $h3-font-size;
      font-weight: 700;
      line-height: toRem(29);
      color: $white;
    }
    h4,
    .h4 {
      font-size: $h4-font-size;
      line-height: toRem(36);
      color: $white;
      margin: 0%;
    }
    h5,
    .h5 {
      font-size: $h5-font-size;
      font-weight: 400;
      line-height: toRem(29);
      color: $white;
    }
    h6,
    .h6 {
      font-size: $h6-font-size;
      font-weight: 700;
      line-height: 24px;
      color: $white;
      margin-bottom: 0px;
    }
    p {
      font-family: $inter;
      line-height: toRem(20);
      font-size: $p-font-size;
      color: $white;
      margin-bottom: 0px;
    }
  }
}

// Margins
.m-4 {
  margin: 4px;
}
.m-8 {
  margin: 8px;
}
.m-12 {
  margin: 12px;
}
.m-16 {
  margin: 16px;
}
.m-20 {
  margin: 20px;
}
.m-24 {
  margin: 24px;
}
.m-28 {
  margin: 28px;
}
.m-32 {
  margin: 32px;
}
.m-36 {
  margin: 36px;
}
.m-40 {
  margin: 40px;
}
.mt-4 {
  margin-top: 4px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-36 {
  margin-top: 36px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-110 {
  margin-top: 110px;
}

.mr-4 {
  margin-right: 4px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-18 {
  margin-right: 18px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-24 {
  margin-right: 24px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-32 {
  margin-right: 32px;
}
.mr-40 {
  margin-right: 40px;
}

.ml-4 {
  margin-left: 4px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-18 {
  margin-left: 18px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-24 {
  margin-left: 24px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-32 {
  margin-left: 32px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-60 {
  margin-left: 60px;
}

.mb-4 {
  margin-bottom: 4px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-18 {
  margin-bottom: 18px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-56 {
  margin-bottom: 56px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-110 {
  margin-bottom: 110px;
}

// Paddings

.p-4 {
  padding: 4px;
}
.p-8 {
  padding: 8px;
}
.p-12 {
  padding: 12px;
}
.p-16 {
  padding: 16px;
}
.p-20 {
  padding: 20px;
}
.p-24 {
  padding: 24px;
}
.p-28 {
  padding: 28px;
}
.p-32 {
  padding: 32px;
}
.p-36 {
  padding: 36px;
}
.p-40 {
  padding: 40px;
}
.p-90 {
  padding: 90px;
}
.pt-0 {
  padding-top: 0px;
}
.pt-4 {
  padding-top: 4px;
}
.pt-8 {
  padding-top: 8px;
}
.pt-12 {
  padding-top: 12px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-18 {
  padding-top: 18px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-24 {
  padding-top: 24px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-32 {
  padding-top: 32px;
}
.pt-40 {
  padding-top: 40px;
}

.pr-0 {
  padding-right: 0px;
}
.pr-4 {
  padding-right: 4px;
}
.pr-8 {
  padding-right: 8px;
}
.pr-12 {
  padding-right: 12px;
}
.pr-16 {
  padding-right: 16px;
}
.pr-18 {
  padding-right: 18px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-24 {
  padding-right: 24px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-32 {
  padding-right: 32px;
}
.pr-40 {
  padding-right: 40px;
}

.pl-0 {
  padding-left: 0px;
}
.pl-4 {
  padding-left: 4px;
}
.pl-8 {
  padding-left: 8px;
}
.pl-12 {
  padding-left: 12px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-18 {
  padding-left: 18px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-24 {
  padding-left: 24px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-32 {
  padding-left: 32px;
}
.pl-40 {
  padding-left: 40px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-4 {
  padding-bottom: 4px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pb-12 {
  padding-bottom: 12px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-18 {
  padding-bottom: 18px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pb-40 {
  padding-bottom: 40px;
}

//font sizes
.fs-12 {
  font-size: $tertiary-fs;
}
.fs-14 {
  font-size: $secondary-fs;
}
.fs-16 {
  font-size: $primary-fs;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: toRem(20);
}
.fs-24 {
  font-size: toRem(24);
}
.fs-28 {
  font-size: toRem(28);
}
.fs-32 {
  font-size: toRem(32) !important;
}

//line heights
.lh-36 {
  line-height: 36px;
}

// font weights

.fw-normal {
  font-weight: 400;
}
.fw-l-bold {
  font-weight: 400;
}
.fw-bold {
  font-weight: 700;
}
.fw-semi-bold {
  font-weight: 600;
}

// border

.border-dgrey {
  border-style: solid;
  border-width: 1px 0;
  border-color: $dgrey;
}
.grey-border {
  border: 1px solid $dgrey;
}

// background color
.bg-red {
  background: $darkred !important;
}

// Common Components

button,
a,
input,
select,
.form-control,
.btn {
  outline: 0;
  box-shadow: none;
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

// form styles
.formstyle {
  ::placeholder {
    font-family: $inter;
    font-weight: 400;
    font-size: $primary-fs;
    line-height: 24px;
    color: grey;
  }
  .input {
    padding: 14px 16px;
    height: 48px;
    font-size: $primary-fs;
    font-family: $inter;
    width: 100%;
    border-radius: 0px;
    border: 1px solid $dgrey !important;
    background: $lblack;
    color: $white;
  }
  .form-select-field {
    background-color: $lblack;
    color: rgba(255, 255, 255, 0.5);
    border: 1px solid $dgrey !important;
    border-radius: 0px;
    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
  .custom-dropdown {
    button {
      background-color: $lblack !important  ;

      width: 100%;
      color: white;
      border: 1px solid $dgrey !important;
      padding: 13px 12px;
      position: relative;
      text-align: left;
      &::after {
        position: absolute;
        right: 20px;
        top: 20px;
        content: "";
      }
      &:active {
        background-color: $lblack ;
        border: 1px solid $dgrey  ;
        color: $white ;
      }
      &:focus {
        background-color: $lblack;
        border: 1px solid $dgrey  ;
        box-shadow: none !important;
        outline: 0;
      }
     
    }
    .custom-dropdown-menu {
      height: 150px;
      width: 100%;
      overflow-y: scroll;
      background-color: $lblack;
      color: $white;
      border: none;
      
      a {
        color: rgba(255, 255, 255, 0.5);
        &:hover {
          color: black;
        }
        &:active {
          background-color: $lblack !important ;
          color: $white;
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
        background-color: $lblack;
      }
      &::-webkit-scrollbar-track {
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: $dgrey;
        border-radius: 3px;
      }
    }
  }

  .input-border {
    border: 1px solid $red;
  }
  .showicon {
    position: relative;
    img {
      position: absolute;
      right: 16px;
      top: 15px;
    }
  }

  .form-control.is-invalid {
    background-image: none;
    &:focus {
      box-shadow: none;
      border-color: #dee2e6;
    }
  }
  .form-switch {
    & .form-check-label {
      color: $white;
      margin-left: 16px;
      vertical-align: middle;
    }
    & .form-check-input {
      width: 42px;
      height: 24px;
      background-origin: border-box;
      background-image: url("../../images/toggle-icon.svg");
      background-size: inherit;
      background-color: black;
      border: 1px solid rgba(196, 196, 196, 0.25);
      &:checked {
        background-image: url("../../images/toggle-icon-checked.svg");
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
}

// max-width and height
.maxw-190 {
  max-width: toRem(190);
}
.maxw-232 {
  max-width: toRem(232);
}
.maxw-300 {
  max-width: toRem(300);
}
.maxw-350 {
  max-width: toRem(350);
}
.w-390 {
  max-width: 390px;
}
.maxw-610 {
  max-width: toRem(610);
}
.w-520 {
  max-width: 520px;
}
.w-810 {
  max-width: 810px;
}
.h-250 {
  min-height: 250px;
}

// cursor
.pointer {
  cursor: pointer;
}

// error
.error {
  color: $red;
  font-size: $tertiary-fs;
}

// check box
#cbSaveInput {
  display: none;
}

#cbSaveInput + label {
  position: relative;
  padding-left: 32px;
  margin-bottom: 0;
  cursor: pointer;
  width: auto;
}
#cbSaveInput + label:before {
  content: "";
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid black;
  border-radius: 2px;
  position: absolute;
  top: 4px;
  left: 0px;
  cursor: pointer;
  height: 16px;
  width: 16px;
}
#cbSaveInput:checked + label:before {
  border: 1px solid black;
  background-color: white;
}
#cbSaveInput + label:after {
  content: "";
  border: solid black;
  border-width: 0 1px 1px 0;
  position: absolute;
  top: 7px;
  left: 6px;
  height: 8px;
  width: 4px;
  transition: all 0.3s;
  transform: rotate(45deg);
  opacity: 0;
}
#cbSaveInput:checked + label:after {
  opacity: 1;
}
// nav links
.navigation-link {
  color: $purple;
  text-decoration: underline;
  font-weight: 700;
}

// text color
.text-light-grey {
  color: $lgrey;
}
.text-grey {
  color: $grey !important;
}
.text-purple {
  color: $purple;
}
.text-success {
  color: $success !important;

}
.text-info {
  color: $yellow !important;
}
.text-error {
  color: $ldanger !important;
}

//custom radio button
.custom-radio-btn {
  padding: toRem(16) toRem(22);
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: toRem(50);
  & input[type="radio"] {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    top: 0;
  }
}
.custom-radio-btn.active {
  background: $blue;
  border: 1px solid $darkblue;
}
// custom checkbox
.custom-checkbox {
  background: #222222;
  border-radius: 5px;
  & input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    appearance: none;
    &::before {
      content: "";
      border-radius: inherit;
      width: inherit;
      display: block;
      height: inherit;
      border-radius: inherit;
      box-shadow: inset 0 0 0 1px #ccd3d8;
    }
    &:checked {
      background-color: blue;
      &::before {
        background-image: url("../../images/custom-checkbox-icon.svg");
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
      }
    }
  }
}
