// Header styles
.header {
  background: $tertiaryBlack;
  padding: 24px;
  .dropdown {
    position: relative;
    &::after {
      position: absolute;
      content: " ";
      width: 32px;
      height: 32px;
      background-color: $lgrey;
      left: -32px;
      border-radius: 50%;
      opacity: 0.3;
      top: 4px;
    }
    .nav-link {
      color: white;
    }
    .dropdown-toggle::after {
      margin-left: 8px;
    }
  }
  .header-navigation {
    ul {
      list-style-type: none;
      margin-bottom: 0px;
      li {
        margin-left: 32px;
        .header-nav-link {
          color: $lgrey;
          font-size: 16px;
          font-weight: 600;
          font-family: $inter;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
}
// Hero Section
.hero-section {
  position: relative;
  height: calc(100vh - 104px);
  width: 100%;
  z-index: 2;
  &::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url("../../images/hero-dots.svg");
    z-index: 1;
  }
  & video {
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .hero-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    text-align: center;
    position: relative;
    z-index: 10;
  }
  .hero-btn {
    padding: toRem(14) toRem(114);
    background: $darkred;
    color: $white;
    font-weight: 900;
    font-size: 18px;
    &:hover {
      background-color: rgba($darkred, 0.75);
    }
    &:focus {
      background-color: rgba($darkred, 0.75);
    }
  }
}
// Benefit section
.benefit-section {
  background: $tertiaryBlack;
  height: 100%;
  width: 100%;
  background-image: url("../../images/benefit-section-img.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 2;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: inherit;
    background: url("../../images/benefit-section-bg.svg");
    z-index: -1;
  }
  .whitelist {
    background: #111111;
    padding-bottom: 50px;
    .whitelist-heading {
      padding: toRem(26) toRem(235) toRem(26) toRem(35);
      display: inline-block;
      background: #333333;
    }
  }
}
// road section
.road-section {
  width: 100%;
  height: 320px;
  background: $tertiaryBlack;
  background-image: url("../../images/footer-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: -40px;
}
// footer-section
.footer-section {
  background: #111111;
  position: relative;
  padding: 40px;
  background-image: url("../../images/footer-img.svg");
  background-repeat: no-repeat;
  background-position: 70%;
  background-position-y: 24px;
}

// button styles
.btn-primary {
  padding: toRem(14) toRem(16);
  border-radius: 0px;
  border: none;
  color: white;
  background: $blue;
  font-size: $p-font-size;
  font-family: $din;
  font-weight: 500;
  line-height: toRem(20);
  &:disabled {
    background-color: $navyblue;
  }
  &:active {
    background: $blue;
    opacity: 0.8;
  }
  &:focus {
    box-shadow: none;
    background: $blue;
  }
  &:hover {
    background: $blue;
    opacity: 0.9;
  }
  &:active:focus {
    box-shadow: none;
  }
}
.btn-gmail {
  padding: toRem(11);
  border-radius: 0px;
  border: none;
  color: white;
  background: $lblack;
  cursor: pointer;
  font-size: $h6-font-size;
  font-weight: 600;
  &:active:focus {
    box-shadow: none;
    background: #484550;
  }
  &:focus {
    box-shadow: none;
    background: #484550;
  }
  &:hover {
    box-shadow: none;
    background: #484550;
  }
}

.btn-secondary {
  padding: 14px 16px;
  border-radius: 2px;
  border: none;
  color: $white;
  background: $lblack;
  cursor: pointer;
  &:active:focus {
    box-shadow: none;
    background: #484550;
  }
}
.btn-secondary:focus {
  box-shadow: none;
}

.outline-dark {
  padding: toRem(14) toRem(80);
  border: 1px solid $white;
  background-color: inherit;
}
// modal style

.modal-width {
  max-width: 520px;
  .modal-style {
    background-color: $dblack;
    border: 1px solid #3a3c3c;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    .modal-body {
      padding: 32px;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: $dgrey;
        border-radius: 3px;
      }
      .headingtext {
        position: relative;
        img {
          top: 0;
          right: 0;
          position: absolute;
        }
      }
      .usd-chain-box {
        background: #222222;
        border-radius: 5px;
      }
      .circle {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: $dgrey;
      }
      .address-box {
        background: $lblack;
        padding: 14px 24px;
        border-radius: 50px;
      }
    }
  }
}

//auth pages

.auth-pages {
  background-color: $black;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 0px;
  &::after {
    position: absolute;
    content: "";
    background: url(../../images/backgound-pattern.svg);
    background-repeat: no-repeat;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-position: right;
    background-position-y: bottom;
    background-position-x: right;
  }
  .auth-container {
    max-width: 440px;
    width: 100%;
    background: $dblack;
    border: 1px solid #3a3c3c;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    padding: toRem(40);
    position: relative;
    z-index: 2;
    &::before {
      content: "";
      position: absolute;
      background: url(../../images/auth-background.svg);
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      z-index: -1;
      left: 0;
      top: 0;
    }
  }
  .auth-footer-content {
    position: relative;
    max-width: 440px;
    z-index: 2;
    a {
      color: $purple;
      text-decoration: underline;
      font-weight: 700;
    }
  }
  .auth-email-validate-field {
    border-radius: 50px;
    padding: toRem(14) toRem(24);
    background-color: $lblack;
  }
  .text-email {
    position: relative;
    &::before {
      content: " ";
      position: absolute;
      width: 44%;
      height: 1px;
      background-color: rgba($color: $dgrey, $alpha: 0.25);
      top: 10px;
      left: 0px;
    }
    &::after {
      content: " ";
      position: absolute;
      width: 44%;
      height: 1px;
      background-color: rgba($color: $dgrey, $alpha: 0.25);
      top: 10px;
      right: 0px;
    }
  }
}

// toast message
.toast-message {
  .message-container {
    z-index: 999999999;
    & .bg-success {
      background-color: $dblack !important;
    }
    & .bg-danger {
      background-color: $dblack !important;
    }
    & .bg-warning {
      background-color: $dblack !important;
    }
  }

  .toast-body {
    background-color: $dblack;
    color: $white;
    font-size: 16px;
    font-family: "inter";
    font-weight: 400;
    border: 1px solid #3a3c3c;
    backdrop-filter: blur(10px);
  }
}

// faqs section
.faqs-section {
  background: $tertiaryBlack;
  .faqs-container {
    background-color: $white;
    .heading {
      position: relative;
      margin-bottom: 30px;
      &::before {
        position: absolute;
        width: 22%;
        height: 20%;
        background-color: $red;
        content: " ";
        left: 40%;
        bottom: -10px;
      }
    }
    .table {
      border: 1px solid $black;
      th {
        &:first-child {
          width: 300px;
          border-right: 1px solid $black;
        }
        &:nth-child(2) {
          width: 300px;
          border-right: 1px solid $black;
        }
        &:nth-child(3) {
          width: 380px;
          border-right: 1px solid $black;
        }
        &:nth-child(4) {
          width: 300px;
        }
      }
      tbody {
        td {
          &:first-child {
            width: 300px;
            border-right: 1px solid $black;
          }
          &:nth-child(2) {
            width: 300px;
            border-right: 1px solid $black;
          }
          &:nth-child(3) {
            width: 380px;
            border-right: 1px solid $black;
          }
          &:nth-child(4) {
            width: 300px;
          }
        }
      }
    }
  }
}

//claim section
.claim-section {
  background-color: $black;
  width: 100%;
  height: 100vh;
  background-image: url("../../images/claim-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .claim-content {
    background: rgba($white, 0.2);
    max-width: 458px;
    width: 100%;

    position: relative;
    padding: toRem(84) toRem(48);
    -webkit-clip-path: polygon(
      10% 0%,
      100% 0%,
      100% 0%,
      100% 90%,
      90% 100%,
      10% 100%,
      0% 100%,
      0% 10%
    );
    &:before {
      content: "";
      max-width: 456px;
      width: 100%;
      height: 99.75%;
      background: rgba($black, 0.8);
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      z-index: -1;
      -webkit-clip-path: polygon(
        10% 0%,
        100% 0%,
        100% 0%,
        100% 90%,
        90% 100%,
        10% 100%,
        0% 100%,
        0% 10%
      );
    }
  }
}
// minting page
.minting-section {
  background-color: $black;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  .bottom-content {
    position: absolute;
    bottom: 60px;
    width: 100%;
  }
  .reveal-view-icon {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #2a4bbd;
      filter: blur(300px);
    }
  }
  .spiner {
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 3s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

// profile page styles
.profile-section {
  background-image: url("../../images/profile-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 160px;
  position: relative;
  &:before {
    position: absolute;

    width: 100%;
    height: 100%;
    content: "";
    background: url("../../images/profile-bg-before.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  h2 {
    position: relative;
    &::before {
      position: absolute;
      content: " ";
      background-color: $red;
      bottom: 0;
      width: 100%;
      height: 5px;
    }
  }
  & .profile-nav {
    & .profile-nav-link {
      color: red;
    }
  }
}
.profile-header-section {
  .profile-user-content {
    padding: toRem(40);
    background: #161616;
    position: relative;
    background: rgba(#555555, 1);
    z-index: 1;

    margin-top: -128px;
    -webkit-clip-path: polygon(
      20% 0%,
      100% 0%,
      100% 0%,
      100% 80%,
      80% 100%,
      20% 100%,
      0% 100%,
      0% 20%
    );
    &:before {
      content: "";

      width: 99%;
      height: 99%;
      background: rgba(#161616, 1);
      display: block;
      position: absolute;
      z-index: -1;
      -webkit-clip-path: polygon(
        20% 0%,
        100% 0%,
        100% 0%,
        100% 80%,
        80% 100%,
        20% 100%,
        0% 100%,
        0% 20%
      );
    }
  }
  .profile-nav-content {
    & .nav-item {
      & a {
        color: rgba($color: $white, $alpha: 0.5);
        font-size: $tertiary-fs;
        font-family: $din;
        font-weight: 700;
      }
      & .active {
        color: $white;
      }
    }
  }
}
